/**
 * vars
 */
var isLoading: boolean = false;

/**
 * init const
 */
const dom = document.documentElement;
const navbar = document.getElementById('navBar');
const links = navbar?.querySelector('.links')?.children;
const loadingScreen = document.getElementById('loadingScreen');
const footerLinks = document.querySelector('footer .links')?.children;

window.onload = (ev: Event) => {
    
    const window = ev.currentTarget as  Window;

    /**
     * 
     */
    window.onscroll = () => {

        if(!document.body.classList.contains('navbar_none')) {

            if (dom.scrollTop >= 100) {
                navbar?.classList.add('fixed')
                // dom
                document.body.classList.add('navbar_fixed')
            }
            else {
                navbar?.classList.remove('fixed')
                // dom
                document.body.classList.remove('navbar_fixed')            
            }
            
        }
        
    }

    /**
     * toggles Between Links in navbar & footer
     */
    if(links instanceof HTMLCollection && links.length > 0) {

        for (let index = 0; index < links.length; index++) {
            
            // get li
            const li = links[index] as HTMLLIElement;
            const footer_li = (footerLinks && footerLinks[index]) as HTMLLIElement;
            //
            const callback = () => {
                for (let i = 0; i < links.length; i++) {
                    links[i].classList.remove('active')
                    footerLinks?.item(i)?.classList.remove('active')
                }
                li.classList.add('active')
                footer_li.classList.add('active')
            }

            /**
             * after page load search for hash
             */
            if (location.hash && li.getAttribute('data-hash')?.split('/').join('') == location.hash) {
                callback()
            }            

            // then add active in current sections is viewing
            li.onclick = callback
            footer_li.onclick = callback             
        }
    }

    /**
     * 
     */
    const shipmentId = new URLSearchParams(location.search).get('shipmentId');

    if (shipmentId) {
        
        const btnSubmit = FormTrackShipment.elements.namedItem('btn_submit') as HTMLButtonElement;
        const shipmentInput = FormTrackShipment.elements.namedItem('shipmentId') as HTMLInputElement;

        shipmentInput.value = shipmentId;

        btnSubmit.click()

    }
    // console.log(location.href.search())
    

    /**
     * loading hide
     */
    setTimeout(() => loadingScreen?.classList.add('d-none'), 1500)

}