/**
 * 
 */
function toggleSlideNavbar(ev: Event) {

    //
    ev.stopPropagation()

    //
    const navbar = document.getElementById('navBar');

    if (navbar != null) navbar.classList.toggle('show')

}