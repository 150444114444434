var _a, _b;
/**
 * vars
 */
var isLoading = false;
/**
 * init const
 */
var dom = document.documentElement;
var navbar = document.getElementById('navBar');
var links = (_a = navbar === null || navbar === void 0 ? void 0 : navbar.querySelector('.links')) === null || _a === void 0 ? void 0 : _a.children;
var loadingScreen = document.getElementById('loadingScreen');
var footerLinks = (_b = document.querySelector('footer .links')) === null || _b === void 0 ? void 0 : _b.children;
window.onload = function (ev) {
    var _a;
    var window = ev.currentTarget;
    /**
     *
     */
    window.onscroll = function () {
        if (!document.body.classList.contains('navbar_none')) {
            if (dom.scrollTop >= 100) {
                navbar === null || navbar === void 0 ? void 0 : navbar.classList.add('fixed');
                // dom
                document.body.classList.add('navbar_fixed');
            }
            else {
                navbar === null || navbar === void 0 ? void 0 : navbar.classList.remove('fixed');
                // dom
                document.body.classList.remove('navbar_fixed');
            }
        }
    };
    /**
     * toggles Between Links in navbar & footer
     */
    if (links instanceof HTMLCollection && links.length > 0) {
        var _loop_1 = function (index) {
            // get li
            var li = links[index];
            var footer_li = (footerLinks && footerLinks[index]);
            //
            var callback = function () {
                var _a;
                for (var i = 0; i < links.length; i++) {
                    links[i].classList.remove('active');
                    (_a = footerLinks === null || footerLinks === void 0 ? void 0 : footerLinks.item(i)) === null || _a === void 0 ? void 0 : _a.classList.remove('active');
                }
                li.classList.add('active');
                footer_li.classList.add('active');
            };
            /**
             * after page load search for hash
             */
            if (location.hash && ((_a = li.getAttribute('data-hash')) === null || _a === void 0 ? void 0 : _a.split('/').join('')) == location.hash) {
                callback();
            }
            // then add active in current sections is viewing
            li.onclick = callback;
            footer_li.onclick = callback;
        };
        for (var index = 0; index < links.length; index++) {
            _loop_1(index);
        }
    }
    /**
     *
     */
    var shipmentId = new URLSearchParams(location.search).get('shipmentId');
    if (shipmentId) {
        var btnSubmit = FormTrackShipment.elements.namedItem('btn_submit');
        var shipmentInput = FormTrackShipment.elements.namedItem('shipmentId');
        shipmentInput.value = shipmentId;
        btnSubmit.click();
    }
    // console.log(location.href.search())
    /**
     * loading hide
     */
    setTimeout(function () { return loadingScreen === null || loadingScreen === void 0 ? void 0 : loadingScreen.classList.add('d-none'); }, 1500);
};
