var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
//
var base_url = "https://test-api.staiship.com";
var stepOnefields = [
    "firstName",
    "lastName",
    "name",
    "phoneNumber",
    "companyName",
    "maroofLink",
    "storeLink",
    "instagramLink",
];
var stepTowfields = ["email", "password"];
var RegisterTraderFrom = document.getElementById("RegisterTraderFrom");
var FormTrackShipment = document.getElementById("FormTrackShipment");
var ShipmentAlertText = document.getElementById("ShipmentAlertText");
var shipmentWrapDetails = document.getElementById("shipmentWrapDetails");
var SHIPMENT_TYPE;
(function (SHIPMENT_TYPE) {
    SHIPMENT_TYPE["AW_PICKUP"] = "AWAITING_PICKUP";
    SHIPMENT_TYPE["AW_PICKUP_AS"] = "AWAITING_PICKUP_ASSIGNMENT";
    SHIPMENT_TYPE["PICKED"] = "PICKED_UP";
    SHIPMENT_TYPE["PICKUP_RETURN"] = "PICKUP_DRIVER_RETURN";
    SHIPMENT_TYPE["AS_DELIVERY"] = "ASSIGNED_DELIVERY";
    SHIPMENT_TYPE["OUT"] = "OUT_FOR_DELIVERY";
    SHIPMENT_TYPE["SORTING_C"] = "SORTING_CENTER";
    SHIPMENT_TYPE["WAREHOUSE_RETURN"] = "DELIVERY_WAREHOUSE_RETURN";
    SHIPMENT_TYPE["WAREHOUSE_RETURN_WAITING"] = "DELIVERY_WAREHOUSE_RETURN_WAITING";
    SHIPMENT_TYPE["RETUREND_TO_M"] = "RETURNED_TO_MERCHANT";
    SHIPMENT_TYPE["ARAMEX_OUT"] = "ARAMEX_OUT_FOR_DELIVERY";
    SHIPMENT_TYPE["DELIVERY_RETURN"] = "DELIVERY_DRIVER_RETURN";
    SHIPMENT_TYPE["DONE"] = "DELIVERED";
})(SHIPMENT_TYPE || (SHIPMENT_TYPE = {}));
/**
 *
 * @param type
 * @returns
 */
function shipmentStatusTimeLine(type, shipment) {
    // ES6 Destruction
    var _a = shipment || {}, merchantName = _a.merchant.name, deliveryAddressName = _a.deliveryAddress.name;
    switch (type) {
        case SHIPMENT_TYPE.AW_PICKUP_AS:
            return "\u0642\u064A\u062F \u0627\u0644\u0645\u0631\u0627\u062C\u0639\u0629 \u0645\u0646: ".concat(merchantName);
        case SHIPMENT_TYPE.PICKED:
            return "\u062A\u0645 \u0627\u0633\u062A\u0644\u0627\u0645 \u0627\u0644\u0634\u062D\u0646\u0629 \u0628\u0646\u062C\u0627\u062D \u0645\u0646: ".concat(merchantName);
        case SHIPMENT_TYPE.SORTING_C:
            return "\u0648\u0635\u0644\u062A \u0627\u0644\u0634\u062D\u0646\u0629 \u0645\u062D\u0637\u0629 \u0627\u0644\u0641\u0631\u0632";
        case SHIPMENT_TYPE.OUT:
        case SHIPMENT_TYPE.ARAMEX_OUT:
            return "\u062C\u0627\u0631\u064A \u062A\u0633\u0644\u064A\u0645 \u0627\u0644\u0634\u062D\u0646\u0629 \u0644\u0644\u0645\u0633\u062A\u0644\u0645";
        case SHIPMENT_TYPE.WAREHOUSE_RETURN:
            return "\u0648\u0635\u0644\u062A \u0627\u0644\u0634\u062D\u0646\u0629 \u0645\u062D\u0637\u0629 \u0627\u0644\u0641\u0631\u0632- \u0634\u062D\u0646\u0629 \u0645\u0639\u0627\u062F\u0629";
        case SHIPMENT_TYPE.RETUREND_TO_M:
            return "\u062A\u0645 \u0627\u0633\u062A\u0644\u0627\u0645 \u0627\u0644\u0634\u062D\u0646\u0629 \u0628\u0646\u062C\u0627\u062D \u0645\u0646 : ".concat(merchantName);
        case SHIPMENT_TYPE.DONE:
            return "\u062A\u0645 \u0627\u0644\u062A\u0633\u0644\u064A\u0645 \u0625\u0644\u0649: ".concat(deliveryAddressName);
        case SHIPMENT_TYPE.WAREHOUSE_RETURN_WAITING:
            return "\u062C\u0627\u0631\u064A \u0625\u0639\u0627\u062F\u0629 \u0627\u0644\u0634\u062D\u0646\u0629 \u0625\u0644\u0649 \u0645\u062D\u0637\u0629 \u0627\u0644\u0641\u0631\u0632";
        default:
            return null;
    }
}
/**
 *
 * @param type
 * @returns
 */
function shipmentStatusText(type) {
    switch (type) {
        case SHIPMENT_TYPE.AW_PICKUP_AS:
            return "\u0642\u064A\u062F \u0627\u0644\u0645\u0631\u0627\u062C\u0639\u0629";
        case SHIPMENT_TYPE.PICKED:
            return "\u062A\u0645 \u0627\u0633\u062A\u0644\u0627\u0645 \u0645\u0646 \u0627\u0644\u062A\u0627\u062C\u0631";
        case SHIPMENT_TYPE.SORTING_C:
            return "\u0645\u062D\u0637\u0629 \u0627\u0644\u062A\u0648\u0632\u064A\u0639";
        case SHIPMENT_TYPE.OUT:
        case SHIPMENT_TYPE.ARAMEX_OUT:
            return "\u062C\u0627\u0631\u064A \u0627\u0644\u062A\u0633\u0644\u064A\u0645";
        case SHIPMENT_TYPE.WAREHOUSE_RETURN:
            return "\u062A\u0645 \u0627\u0644\u0627\u0631\u062C\u0627\u0639 \u0644\u0644\u0641\u0631\u0632";
        case SHIPMENT_TYPE.RETUREND_TO_M:
            return "\u062A\u0645 \u0627\u0644\u0627\u0631\u062C\u0627\u0639";
        case SHIPMENT_TYPE.DONE:
            return "\u062A\u0645 \u0627\u0644\u062A\u0633\u0644\u064A\u0645";
        default:
            return null;
    }
}
/**
 *
 * @param event
 */
function redirectToTrackShipment(event) {
    /**
     * preventDefault
     */
    event.preventDefault();
    /**
     * init
     */
    var btn = event.submitter;
    // ES6 Destruction
    var form = event.currentTarget;
    /**
     * fields
     */
    var shipmentId = form.elements.namedItem("shipmentId");
    if (shipmentId.checkValidity()) {
        window.location.href = "/shipment-tracking.html?shipmentId=".concat(shipmentId.value);
    }
}
/**
 *
 * @param id shipment id
 */
function trackShipment(event) {
    var _a, _b, _c, _d;
    return __awaiter(this, void 0, void 0, function () {
        var btn, form, shipmentId, ft, json, _e, shipment_1, history_1, ulDetails, ulTimeLine, id, inRiyadh_1, description, _f, city_1, country_1, createdAt, formate, err_1;
        return __generator(this, function (_g) {
            switch (_g.label) {
                case 0:
                    /**
                     * preventDefault
                     */
                    event.preventDefault();
                    btn = event.submitter;
                    form = event.currentTarget;
                    shipmentId = form.elements.namedItem("shipmentId");
                    if (!(shipmentId.checkValidity() && isLoading == false)) return [3 /*break*/, 5];
                    _g.label = 1;
                case 1:
                    _g.trys.push([1, 4, , 5]);
                    /**
                     * start loading
                     */
                    isLoading = true;
                    btn.disabled = true;
                    btn.textContent = "بحث...";
                    /**
                     *
                     */
                    ShipmentAlertText.classList.remove("d-none");
                    shipmentWrapDetails.classList.add("d-none");
                    (_a = ShipmentAlertText.firstElementChild) === null || _a === void 0 ? void 0 : _a.classList.add("d-none");
                    (_b = ShipmentAlertText.lastElementChild) === null || _b === void 0 ? void 0 : _b.classList.remove("d-none");
                    return [4 /*yield*/, fetch("".concat(base_url, "/client/track/").concat(shipmentId.value), {
                            method: "GET",
                            headers: {
                                "Content-Type": "application/json"
                            }
                        })];
                case 2:
                    ft = _g.sent();
                    return [4 /*yield*/, ft.json()];
                case 3:
                    json = _g.sent();
                    _e = json, shipment_1 = _e.shipment, history_1 = _e.history;
                    /**
                     * throw Error
                     */
                    if (!ft.ok || shipment_1 == null)
                        throw ft;
                    /**
                     * show shipment wrap Details
                     */
                    ShipmentAlertText.classList.add("d-none");
                    shipmentWrapDetails.classList.remove("d-none");
                    ulDetails = shipmentWrapDetails.querySelector(".ul_details");
                    ulTimeLine = shipmentWrapDetails.querySelector(".ul_time_line");
                    id = shipment_1.id, inRiyadh_1 = shipment_1.inRiyadh, description = shipment_1.description, _f = shipment_1.deliveryAddress, city_1 = _f.city, country_1 = _f.country, createdAt = shipment_1.createdAt;
                    formate = new Date(createdAt).toLocaleDateString("ar-EN", {
                        month: "long",
                        day: "numeric",
                        hour: "numeric",
                        year: "numeric",
                        minute: "2-digit"
                    });
                    ulDetails.innerHTML = "\n                <li class=\"font_14 font_font_400 line_h_24 margin_bottom_5\">\n                    <span class=\"d-inline-block margin_end\">\u0631\u0642\u0645 \u0627\u0644\u0637\u0644\u0628 :</span>\n                    <span class=\"d-inline-block font_600\">#".concat(id, "</span>\n                </li>\n                <li class=\"font_14 font_font_400 line_h_24 margin_bottom_5\">\n                    <span class=\"d-inline-block margin_end\">\u062A\u0627\u0631\u064A\u062E \u0627\u0644\u0637\u0644\u0628 :</span>\n                    <span class=\"d-inline-block font_300\">").concat(formate, "</span>\n                </li>\n                <li\n                    class=\"font_14 font_font_400 line_h_24 margin_bottom_5 d-flex\">\n                    <span class=\"d-inline-block margin_end\">\u062D\u0627\u0644\u0629 \u0627\u0644\u0637\u0644\u0628 :</span>\n                    <div class=\"app_badge default color_green d-inline-block\">\n                        ").concat(shipmentStatusText(history_1[0].status), "\n                    </div>\n                </li>\n                <li class=\"font_14 font_font_400 line_h_24\">\n                    <span class=\"d-inline-block margin_end\">\u0648\u0635\u0641 \u0627\u0644\u0634\u062D\u0646\u0629:</span>\n                    <span class=\"d-inline-block font_300\">").concat(description, "</span>\n                </li>\n            ");
                    ulTimeLine.innerHTML = history_1
                        .map(function (h) {
                        var d = new Date(h.timestamp)
                            .toLocaleDateString("ar-EN", {
                            month: "long",
                            day: "numeric",
                            hour: "numeric",
                            year: "numeric",
                            minute: "2-digit"
                        })
                            .split(" ");
                        if (shipmentStatusTimeLine(h.status, shipment_1)) {
                            return "\n                        <li class=\"padding_bottom_12 active font_12 font_300 color_text d-flex align-items-center\">\n                            <div class=\"wrap_date\">\n                                <span class=\"d-block\">".concat(d[0], " ").concat(d[1], " ").concat(d[2], "</span>\n                                <span class=\"d-block\">").concat(d[4], " ").concat(d[5], "</span>\n                            </div>\n                            <div class=\"wrap_progress d-flex align-items-center justify-content-center\">\n                                <span class=\"progress_dot\"></span>\n                            </div>\n                            <div class=\"wrap_status line_h_24\">\n                                <span class=\"d-block font_400\">\n                                    ").concat(shipmentStatusTimeLine(h.status, shipment_1), "\n                                </span>\n                                <span class=\"d-block font_300 sub_color_md_text\">\n                                    ").concat((SHIPMENT_TYPE.ARAMEX_OUT || SHIPMENT_TYPE.DONE) && inRiyadh_1 == false ? country_1 + ", " + city_1 : "السعودية, الرياض", "\n                                </span>\n                            </div>\n                        </li>\n                    ");
                        }
                    })
                        .join(" ");
                    /**
                     * stop loading
                     */
                    isLoading = false;
                    btn.disabled = false;
                    btn.textContent = "تتبع";
                    return [3 /*break*/, 5];
                case 4:
                    err_1 = _g.sent();
                    /**
                     * stop loading
                     */
                    isLoading = false;
                    btn.disabled = false;
                    btn.textContent = "تتبع";
                    /**
                     * show not found shipment alert
                     */
                    ShipmentAlertText.classList.remove("d-none");
                    (_c = ShipmentAlertText.lastElementChild) === null || _c === void 0 ? void 0 : _c.classList.add("d-none");
                    (_d = ShipmentAlertText.firstElementChild) === null || _d === void 0 ? void 0 : _d.classList.remove("d-none");
                    /**
                     * hide shipment wrap Details
                     */
                    shipmentWrapDetails.classList.add("d-none");
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    });
}
/**
 *
 * @param event
 */
function asyncShipmentId(event) {
    /**
     * element will show shipmentId
     */
    var shipmentElement = document.getElementById("ShipmentId");
    /**
     * throw Error
     */
    if (shipmentElement == null)
        throw new Error("shipmentId is ".concat(shipmentElement));
    /**
     * target input field
     */
    var input = event.target;
    /**
     * filter text to get number only
     */
    var numberOnly = input.value.replace(/[^0-9]/g, "");
    /**
     * set input to remove trash chars
     */
    input.value = numberOnly;
    /**
     * async same Id
     */
    shipmentElement.textContent = "#".concat(numberOnly.trim());
}
/**
 *
 * @param event
 * @description submit from contact us
 */
function submitContactUs(event) {
    return __awaiter(this, void 0, void 0, function () {
        var btn, form, name, email, message, reason, title, formData, ft, err_2, message_1, error;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    /**
                     *
                     */
                    event.preventDefault();
                    btn = event.submitter;
                    form = event.currentTarget;
                    name = form.elements.namedItem("name").value;
                    email = form.elements.namedItem("email").value;
                    message = form.elements.namedItem("message")
                        .value;
                    reason = form.elements.namedItem("reason").value;
                    title = form.elements.namedItem("title").value;
                    formData = {
                        name: name,
                        email: email,
                        message: message,
                        reason: reason,
                        title: title
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    /**
                     *
                     */
                    btn.disabled = true;
                    btn.textContent = "جاري الارسال";
                    return [4 /*yield*/, fetch("".concat(base_url, "/client/contact-us"), {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify(formData)
                        })];
                case 2:
                    ft = _a.sent();
                    /**
                     * throw Error
                     */
                    if (ft.status !== 201)
                        throw new Error("حدث خطأ ما");
                    /**
                     * rest form
                     */
                    form.reset();
                    Swal.fire({
                        icon: "success",
                        confirmButtonText: "موافق",
                        title: "تم ارسال طلبك بنجاح"
                    });
                    /**
                     *
                     */
                    btn.disabled = false;
                    btn.textContent = "ارسال";
                    return [3 /*break*/, 4];
                case 3:
                    err_2 = _a.sent();
                    message_1 = err_2.message, error = err_2.error;
                    Swal.fire({
                        text: message_1,
                        title: error,
                        icon: "error",
                        confirmButtonText: "موافق"
                    });
                    /**
                     *
                     */
                    btn.disabled = false;
                    btn.textContent = "ارسال";
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
/**
 *
 */
function stepOneIsValid() {
    /**
     * filter fields to get enabled input
     */
    var arr = stepOnefields.filter(function (field) {
        var input = RegisterTraderFrom.elements[field];
        return input.disabled == false;
    });
    /**
     * then check in validity
     */
    var isValid = arr.every(function (field) {
        var input = RegisterTraderFrom.elements[field];
        return input.validity.valid;
    });
    if (isValid)
        nextStep("stepTow");
    else
        RegisterTraderFrom.reportValidity();
}
/**
 *
 * @param event
 */
function submitRegisterTrader(event) {
    return __awaiter(this, void 0, void 0, function () {
        var formData, btn, form, defaultUi, ft, json, myModal, err_3, message, error;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    /**
                     *
                     */
                    event.preventDefault();
                    formData = { employeeName: "" };
                    btn = event.submitter;
                    form = event.currentTarget;
                    if (!(form.checkValidity() && isLoading == false)) return [3 /*break*/, 6];
                    defaultUi = btn.innerHTML;
                    /**
                     * start loading
                     */
                    isLoading = true;
                    btn.disabled = true;
                    btn.textContent = "جاري التحقق";
                    /**
                     *
                     */
                    stepOnefields.concat(stepTowfields).forEach(function (field) {
                        var input = form.elements.namedItem(field);
                        if (!input.disabled) {
                            if (field == "firstName") {
                                formData["employeeName"] += input.value;
                            }
                            if (field == "lastName") {
                                formData["employeeName"] += " " + input.value;
                            }
                            else
                                formData[field] = input.value;
                            if (field === "phoneNumber") {
                                formData["phoneNumber"] = "+966" + formData["phoneNumber"];
                            }
                        }
                    });
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, fetch("".concat(base_url, "/auth/merchant/register"), {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify(formData)
                        })];
                case 2:
                    ft = _a.sent();
                    return [4 /*yield*/, ft.json()];
                case 3:
                    json = _a.sent();
                    /**
                     * throw Error
                     */
                    if (!ft.ok)
                        throw json;
                    /**
                     * rest form
                     */
                    form.reset();
                    myModal = new bootstrap.Modal("#staticBackdrop", {
                        keyboard: false
                    });
                    myModal.show();
                    /**
                     * stop loading
                     */
                    isLoading = false;
                    btn.disabled = false;
                    btn.innerHTML = defaultUi;
                    return [3 /*break*/, 5];
                case 4:
                    err_3 = _a.sent();
                    message = err_3.message, error = err_3.error;
                    Swal.fire({
                        text: message,
                        title: error,
                        icon: "error",
                        confirmButtonText: "موافق"
                    });
                    /**
                     * stop loading
                     */
                    isLoading = false;
                    btn.disabled = false;
                    btn.innerHTML = defaultUi;
                    return [3 /*break*/, 5];
                case 5: return [3 /*break*/, 7];
                case 6:
                    if (isLoading == false)
                        form.reportValidity();
                    _a.label = 7;
                case 7: return [2 /*return*/];
            }
        });
    });
}
/**
 *
 * @param event input event
 * @param errorElementId element will show current error
 */
function validationInput(event, errorElementId) {
    var _a;
    /**
     * stop default behavior
     */
    event.preventDefault();
    /**
     * init
     */
    var input = event.target;
    var container = (_a = input.parentElement) === null || _a === void 0 ? void 0 : _a.parentElement;
    var errorMsg = document.getElementById(errorElementId || "");
    /**
     *
     */
    if (errorMsg == null)
        throw new Error("errorElementId is undefined");
    if (container == null)
        throw new Error("container is undefined");
    // ES6 Destruction
    var required = input.required, minLength = input.minLength, maxLength = input.maxLength, pattern = input.pattern;
    var _b = input.validity, valueMissing = _b.valueMissing, valid = _b.valid, tooShort = _b.tooShort, tooLong = _b.tooLong, patternMismatch = _b.patternMismatch;
    if (valid == false) {
        //
        container.classList.add("in_valid");
        container.classList.remove("is_valid");
        //
        errorMsg.classList.remove("d-none");
        //
        if (valueMissing && required) {
            errorMsg.textContent = "من فضلك ادخل هذا الحقل";
        }
        else if (patternMismatch) {
            errorMsg.textContent = "صيفة الادخال غير صحيحة";
            if (input.hasAttribute("data-pattern-replace")) {
                input.value = input.value.replace(new RegExp(input.getAttribute("data-pattern-replace"), "g"), "");
            }
        }
        else if (tooShort) {
            errorMsg.textContent = "\u0627\u0642\u0644 \u0639\u062F\u062F \u0645\u0646 \u062D\u0631\u0648\u0641 \u0647\u0648 ".concat(minLength);
        }
        else if (tooLong) {
            errorMsg.textContent = "\u0627\u0643\u062B\u0631 \u0639\u062F\u062F \u0645\u0646 \u062D\u0631\u0648\u0641 \u0647\u0648 ".concat(maxLength);
        }
    }
    else {
        container.classList.add("is_valid");
        container.classList.remove("in_valid");
        if (errorMsg.textContent != "")
            errorMsg.textContent = "";
        if (!errorMsg.classList.contains("d-none"))
            errorMsg.classList.add("d-none");
    }
}
/**
 *
 * @param check radio check Yes or no
 * @param questionId container for input field
 */
function radioQuestionToggle(check, questionId) {
    if (questionId == null)
        throw new Error("questionId arg is ".concat(questionId));
    /**
     * get element
     */
    var q = document.getElementById(questionId);
    var input = q === null || q === void 0 ? void 0 : q.querySelector(".input_field input");
    /**
     * check if elementId is Available
     */
    if (q == null)
        throw new Error("Element is ".concat(questionId));
    if (input == null)
        throw new Error("input is ".concat(input));
    /**
     * show input or hide
     */
    if (check) {
        input.required = true;
        input.disabled = false;
        q.classList.remove("d-none");
    }
    else {
        input.required = false;
        input.disabled = true;
        q.classList.add("d-none");
    }
}
