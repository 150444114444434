/**
 * 
 * @param currentStepper next element Will showing
 */
function changeStepperContent(currentStepper: string) {

    //
    const current = document.getElementById(currentStepper);
    const steps = document.querySelectorAll('.stepper_content .step');

    if(steps instanceof NodeList && steps.length > 0) {
        steps.forEach((step) => step.classList.remove('active')) 
    }   

    current?.classList.add('active')

}

function stepperProgress(currentStep: string, back?: boolean) {

    //
    const li = document.querySelector(`[data-stepNumber=${currentStep}]`);

    //
    if (back != null) {
        li?.nextElementSibling?.classList.remove('active')
        li?.classList.add('active')
    }
    else li?.classList.add('active')
    
}

/**
 * 
 */
function nextStep(nextElStep: string): void {

    //
    changeStepperContent(nextElStep);

    //
    stepperProgress(nextElStep)

}

/**
 * 
 */
function previousStep(previousElStep: string): void {

    if(isLoading == false) {

        //
        changeStepperContent(previousElStep);
    
        //
        stepperProgress(previousElStep, true)

    }
    console.warn('form is Loading')


}